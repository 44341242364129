<template>
  <b-container class="min-vh-100 d-flex flex-column">
    <BackButton @click="$router.push('/driving')"/>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="9">
        <h1 style="margin-top: 4.25rem; margin-bottom: 2rem;">{{$store.state.bike.name}}</h1>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <p>Bist du dir sicher, dass du das Fahrrad abschließen willst? Du kannst das Fahrrad auch für 30 min reservieren. </p>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <img src="@/assets/illustrations/bikergreenshirt.svg" class="illustration" alt="biker" style="width: 100%; height: 100%; margin-bottom: 2rem;"/>
        </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row style="text-align: center;" class="flex-grow-1">
      <b-col cols="1"/>
      <b-col cols="10" class="justify-content-center align-self-center">
        <b-button variant="secondary" class="buttonSpacing" @click="$router.push('/pause/info')">Pausieren</b-button>
        <b-button variant="primary" class="buttonSpacing" @click="lockBike">Sperren</b-button>
      </b-col>
      <b-col cols="1"/>
    </b-row>
  </b-container>
</template>

<script>
import BackButton from '../components/BackButton';
export default {
  name: 'Pause',
  data() {
    return {
    }
  },
  components: {
    BackButton,
  },
  methods: {
    lockBike() {
      // sends lock message to the bike
      this.axios.post('/map/lockBike', {
            bikeId: this.$store.state.bike._id
            }, {
            headers: {
              token: localStorage.getItem('token')
            }
        })
          .then((res) => {
            if (res.status === 200) {
              this.toast(`${this.$store.state.bike.name} wird entsperrt`, 'Das Bike wird vom Sever kontaktiert…', 'info');
              this.$router.push('/');
            } else {
              this.toast('Es ist ein Fehler aufgetreten', 'Bitte versuche es erneut', 'danger');
            }
          })
          .catch((err) => {
            console.error(err);
            this.toast('Es ist ein Fehler aufgetreten', 'Bitte versuche es erneut', 'danger');
          });
    },
  },
}
</script>

<style scoped>
</style>